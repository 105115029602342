<script setup>
const { $repository } = useNuxtApp()
const { locale, locales } = useI18n()
const route = useRoute()
const localeRoute = useLocaleRoute()
const getRouteBaseName = useRouteBaseName()

const emit = defineEmits(['close', 'back'])

const loadingChangeLocale = ref(false)

const activeClass = computed(() => (type) => locale.value === type && 'bg-primary-500 bg-opacity-5 text-primary-700')

const changeLocale = async (type) => {
	loadingChangeLocale.value = true
	await $repository.v3.hire.setLanguage({ language: type })
	loadingChangeLocale.value = false

	const name = getRouteBaseName(route)
	const toRoute = localeRoute(name, type)
	const { origin, search, hash } = window.location

	window.location = origin + toRoute.path + search + hash
}
</script>

<template>
	<div class="relative">
		<div
			v-if="loadingChangeLocale"
			class="flex items-center justify-center absolute inset-0 bg-white bg-opacity-70"
		>
			<UCircleLoader
				:size="24"
				border-width="border-3"
			/>
		</div>
		<div class="p-3 border-b flex items-center">
			<a
				href="#"
				class="-m-2 mr-0.5 p-2 leading-none rounded-full hover:bg-primary-500 hover:bg-opacity-10"
				@click.prevent.stop="emit('back')"
			>
				<Icon
					name="material-symbols:arrow-back-rounded"
					size="16"
				/>
			</a>
			<span class="font-semibold">{{ $t('Selecciona tu idioma') }}</span>
		</div>

		<ul class="space-y-0.5">
			<li
				v-for="(lang, index) in locales"
				:key="index"
			>
				<a
					:href="`/${lang.code}`"
					class="flex items-center p-3 text-sm hover:bg-primary-50"
					:class="activeClass(lang.code)"
					@click.prevent="changeLocale(lang.code)"
				>
					<span class="-my-1 mr-2 text-lg">
						<Icon
							:name="lang.icon"
							class="overflow-hidden rounded-full"
						/>
					</span>
					<span>{{ lang.name }}</span>
				</a>
			</li>
		</ul>
	</div>
</template>
