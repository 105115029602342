<script setup>
import LanguageMenu from '~/components/layout/header/user-menu/LanguageMenu.vue'

const { t: $t, locale, locales } = useI18n()
const { $auth } = useNuxtApp()

const showLanguageMenu = ref(false)

const getName = computed(() => {
	if ($auth.user.name || $auth.user.last_name) {
		return `${$auth.user.name} ${$auth.user.last_name}`
	}
	return $auth.user.business.name
})

const currentLanguage = computed(() => {
	return locales.value.find((l) => l.code === locale.value)?.name
})

const closeMenu = () => {
	showLanguageMenu.value = false
	document.body.click()
}

const changeLanguage = () => {
	showLanguageMenu.value = true
}
</script>

<template>
	<div class="overflow-auto">
		<div v-if="!showLanguageMenu">
			<div class="border-b p-3">
				<div class="font-semibold line-clamp-2 mb-1">
					{{ getName }}
				</div>
				<span class="text-[12px] truncate block">{{ $auth.user.email }}</span>
			</div>

			<ul class="space-y-0.5">
				<template v-if="$auth?.user?.wizard?.completed">
					<li>
						<NuxtLinkLocale
							to="/account/plan"
							class="flex items-center p-3 hover:bg-primary-50"
							@click="closeMenu"
						>
							<span class="flex items-center">
								<Icon
									name="mdi:card-bulleted-outline"
									class="mr-1.5 opacity-60"
									size="17"
								/>
							</span>
							<span>{{ $t('Mi plan') }}</span>
						</NuxtLinkLocale>
					</li>
					<li>
						<NuxtLinkLocale
							to="/account/company"
							class="flex items-center p-3 hover:bg-primary-50"
							@click="closeMenu"
						>
							<span class="flex items-center">
								<Icon
									name="mdi:smart-card-outline"
									class="mr-1.5 opacity-60"
									size="17"
								/>
							</span>
							<span>{{ $t('Perfil') }}</span>
						</NuxtLinkLocale>
					</li>
					<li>
						<NuxtLinkLocale
							to="/account/billing"
							class="flex items-center p-3 hover:bg-primary-50"
							@click="closeMenu"
						>
							<span class="flex items-center">
								<Icon
									name="mdi:credit-card-outline"
									class="mr-1.5 opacity-60"
									size="17"
								/>
							</span>
							<span>{{ $t('Facturación') }}</span>
						</NuxtLinkLocale>
					</li>
				</template>
				<li>
					<a
						href="/lang/"
						class="flex items-center p-3 hover:bg-primary-50 justify-between"
						@click.prevent.stop="changeLanguage"
					>
						<span class="flex items-center">
							<Icon
								name="material-symbols:language"
								class="mr-1.5 opacity-60"
								size="17"
							/>
							<span>{{ $t('Idioma') }}: {{ currentLanguage }}</span>
						</span>
						<span>
							<Icon
								name="material-symbols:arrow-forward-ios-rounded"
								size="12"
							/>
						</span>
					</a>
				</li>
				<li>
					<NuxtLinkLocale
						to="/logout"
						class="flex items-center p-3 hover:bg-primary-50"
					>
						<span class="flex items-center">
							<Icon
								name="material-symbols:logout"
								class="mr-1.5 opacity-60"
								size="17"
							/>
						</span>
						<span>{{ $t('Cerrar sesión') }}</span>
					</NuxtLinkLocale>
				</li>
			</ul>
		</div>

		<LanguageMenu
			v-else
			@close="closeMenu"
			@back="showLanguageMenu = false"
		/>
	</div>
</template>
