<template>
	<div class="relative">
		<component
			:is="tag"
			class="focus:outline-none"
			type="button"
			:class="buttonClass"
			@click.stop="toggleMenu()"
		>
			<slot />
		</component>

		<div
			v-if="showMenu"
			ref="dropdown"
			:class="[position, menuClass]"
			:style="styles"
		>
			<slot name="dropdown" />
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	styles: {
		type: Object,
		default() {
			return {}
		},
	},
	position: {
		type: String,
		default: 'bottom-center',
	},
	buttonClass: {
		type: String,
		default: '',
	},
	menuClass: {
		type: String,
		default: 'dropdown',
	},
	closeOnOutsideClick: {
		type: [Boolean],
		default: true,
	},
	btnClass: {
		type: String,
		default: '',
	},
	tag: {
		type: String,
		default: 'button',
	},
	showOpen: {
		type: Boolean,
		default: false,
	},
})

const emit = defineEmits(['update'])

const showMenu = ref(false)
const dropdown = ref(null)

watchEffect(() => {
	if (!dropdown.value) return

	if (props.closeOnOutsideClick) {
		document.addEventListener('click', clickHandler)
	}

	if (!props.showOpen) return
	emit('update', showMenu.value)
})

onBeforeUnmount(() => {
	document.removeEventListener('click', clickHandler)
})

const toggleMenu = () => {
	if (!showMenu.value) {
		document.body.click()
	}
	showMenu.value = !showMenu.value
}

const clickHandler = (event) => {
	if (!dropdown.value) return

	const { target } = event

	if (!dropdown.value.contains(target)) {
		showMenu.value = false
	}
}
</script>

<style lang="postcss">
.dropdown {
	top: calc(100% + 10px);
	@apply absolute;

	&.top {
		bottom: 100%;
	}

	&.left {
		left: 0;
	}

	&.right {
		right: 0;
	}

	&.center {
		top: 50%;
		transform: translate(-50%, -50%);
		left: 50%;
	}

	&.bottom-center {
		left: 50%;
		transform: translateX(-50%);
	}

	&.right-center {
		@apply smm:-top-16 lg:top-5 3xl:-top-16 smm:right-0 lg:left-0 3xl:left-3/4;
	}
}
</style>
